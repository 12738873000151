<template>
  <dropdown-menu :items="actions" :select="onActionSelect" @click.native.stop
    ><font-awesome-icon icon="ellipsis-h" />
  </dropdown-menu>
</template>

<script>
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";

export default {
  name: "OrdersTableActions",

  data() {},
  computed: {
    actions: function () {
      const canEdit = this.canView(PERMS.ORDERS_EDIT);
      const canEditDirect = canEdit && !this.data.direct_delivery;
      return [
        {
          id: ORDER_ACTION.FILTER,
          text: this.$t("role.orders.rounds.ddmp.orderActions.filter"),
        },
        {
          id: ORDER_ACTION.DONE,
          text: this.$t(
            "role.orders.rounds.ddmp.detail.tabs.products.table.modify.markAsPickedUp"
          ),
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: this.$t(
            "role.orders.rounds.ddmp.detail.tabs.products.table.modify.markAsNotPickedUp"
          ),
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: this.$t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.openIncident"
          ),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: this.$t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.cancel"
          ),
          disabled: !canEdit,
        },
      ];
    },
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      const porders = [this.data.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (id === ORDER_ACTION.FILTER) {
        const orderId = this.data.order.order.id;
        const param = { id: orderId, label: orderId };
        this.$store.commit("rounds/updateOrderId", param);
        EventBus.$emit("orders:applyFilters");
      }

      if (id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.next"),
            porders,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            porders,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PORDERS, items: [{ ...this.data }] }, // FIXME
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PORDERS, items: [{ ...this.data }] }, // FIXME
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
